/* eslint-disable max-lines */
import { SYSTEMS_NAMES } from 'wikr-core-analytics/dist/constants';
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { selectFullURL, selectUserId } from 'redux/User/selectors';
import { setPaidStatus, setUserProducts } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import {
    selectCurrency,
    selectCurrentProduct,
    selectPaymentMethod,
    selectPurchaseWithoutValue,
    selectUpgradedProduct,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import {
    setLoadingStatus,
    setPaymentDataAfterValidate,
    setSubscriptionId,
    validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';
import { selectURLParams } from 'redux/Onboadring/selectors';

import { DEVICE_OS, PAYMENT_TYPES, PAYMENT_TYPES_NAME } from 'constants/payment/payments';
import { DEFAULT_ACTIVE_ANALYTICS } from 'constants/analytic/analytic';

import { getDeviceOS } from 'helpers/other/userAgentUtils';
import { cleanObject } from 'helpers/other/dataStructuresUtils';
import normalizeValidatePayload from './helpers/normalizeValidatePayload';
import normalizeData from './helpers/normalizeData';
import getValidateData from './helpers/getValidateData';
import { getPurchaseOptions } from './helpers/getPurchaseOptions';

import { ValidateResponse } from 'types/payments/validate';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import {
    currentProductType,
    DataAfterNormalization,
    PaymentMethod,
    PurchaseAnalyticData,
    RawData,
    ValidatePayloadAfterNormalization,
} from 'interfaces/Payments/payments';
import { Currency } from '../user/interfaces';

function* getAnalyticData() {
    const currentProduct: currentProductType = yield select(selectCurrentProduct);
    const upgradedProduct: currentProductType = yield select(selectUpgradedProduct);
    const currency: Currency = yield select(selectCurrency);
    const purchaseWithoutValue: boolean = yield select(selectPurchaseWithoutValue);
    const userId: number = yield select(selectUserId);
    const testaniaName: string = yield select(selectTestaniaName);
    const fullURL: string = yield select(selectFullURL);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const deviceOS: string = getDeviceOS();
    const platform = deviceOS === DEVICE_OS.ANDROID || deviceOS === DEVICE_OS.IOS ? deviceOS : DEVICE_OS.ANDROID;

    const defaultAmazonPurchaseData: Record<string, any> = yield Main.getDefaultAmazonAnalyticPurchaseData();

    return {
        currentProduct,
        upgradedProduct,
        currency,
        userId,
        testaniaName,
        platform,
        fullURL,
        paymentMethodName,
        config: {
            purchaseWithoutValue,
        },
        defaultAmazonPurchaseData,
    };
}

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    const {
        data: { order, brand = '', paymentMethod },
        screenId,
        analytics,
    } = payload;

    try {
        yield put(setLoadingStatus(true));
        yield put(setPaidStatus(true));
        yield put(setUserProducts({ main_plan: true }));

        let paymentMethodName = String(paymentMethod);

        if (!paymentMethod) {
            paymentMethodName = yield select(selectPaymentMethod);
        }

        const paymentMethodType = PAYMENT_TYPES[paymentMethodName as keyof typeof PAYMENT_TYPES];

        const analyticData: RawData = yield call(getAnalyticData);

        const normalizedData: DataAfterNormalization = normalizeData(analyticData);

        const validatePayload: ValidatePayloadAfterNormalization = normalizeValidatePayload({
            amount: order?.amount,
            screenId,
            paymentMethod: paymentMethodType,
            currency: normalizedData.currency,
        });

        const finalValidateMeta = getValidateData(
            normalizedData,
            validatePayload,
            order,
            brand,
            Number(paymentMethodType)
        );

        const postInitResponse: { data: ValidateResponse } = yield api.payment.postInit(finalValidateMeta);

        const isPendingStatus = postInitResponse.data.is_pending;

        yield put(setPaymentDataAfterValidate({ ...postInitResponse.data }));
        const urlParams: Record<string, string> = yield select(selectURLParams);

        const options = {
            currency: normalizedData.currency,
            value: normalizedData.value,
            subscription_price: normalizedData.subscription_price,
            tariff: normalizedData.tariff,
            ab_test_name: normalizedData.ab_test_name,
            user_id: normalizedData.user_id,
            order_type: normalizedData.payment_type,
            payment: normalizedData.payment_method_name,

            price: validatePayload.amountValue,
            screen_id: validatePayload.pageUrl,

            event_id: order.order_id,
            order_id: order.order_id,
            subscription_id: order.subscription_id,

            card_type: brand,
            content_id: finalValidateMeta.analytic_data.content_id,
            analytics,
        } as PurchaseAnalyticData;

        const payload: PurchaseAnalyticData = {
            ...options,
            ...getPurchaseOptions(
                {
                    period: normalizedData.subscription_period,
                    trial: normalizedData.subscription_trial_period,
                },
                urlParams
            ),
        };

        isPendingStatus ? sendAnalyticPurchasePending(options) : sendAnalyticPurchase(payload);

        yield put(setSubscriptionId(order.subscription_id));
        yield put(setLoadingStatus(false));
    } catch (error) {
        yield put(setLoadingStatus(false));

        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, { ...payload });
    }
}

function sendAnalyticPurchase(payload: PurchaseAnalyticData) {
    Main.purchaseNew(cleanObject(payload), {
        selectedSystems: payload.analytics.length
            ? Array.from(payload.analytics, (system) => system as SYSTEMS_NAMES)
            : DEFAULT_ACTIVE_ANALYTICS,
    });
}

function sendAnalyticPurchasePending(payload: PurchaseAnalyticData) {
    Main.customData('purch_pending', cleanObject(payload));
}

export const validatePayment = function* () {
    yield takeLatest(actionTypes.VALIDATE_PAYMENT, validate);
};
